<script>
import { orderComputed, orderMethods, productComputed, productMethods, adminComputed } from "@/state/helpers";
import _ from "lodash";
import { cloneDeep } from "lodash";
//import Loader from "./loader";

/**
 * Orders component
 */
export default {
    props: {
        order_data: {
            required: true,
        },
        read_only: {
            default: true,
            required: false,
        },
    },
    components: {
        //Loader,
    },
    watch: {
        order_data() {
            this.setTableData();
        },
    },
    computed: {
        ...adminComputed,
        ...orderComputed,
        ...productComputed,
        paginatedItems() {
            return this.table_data.slice(this.currentPage * this.perPage - this.perPage, this.currentPage * this.perPage);
        },
        mustIncludeVAT() {
            if (this.order_data == null) {
                //* showing token page.. no core data loaded
                return false;
            }
            return this.order_data.site_config.vat_enabled == 1;
        },
        searchProducts() {
            return _.map(this.all_products, "name");
        },
    },
    data() {
        return {
            orderToUpdate: {
                contact_email: "",
                courier_url: "",
                contact_person: "",
                contact_no: "",
                delivery_address: "",
                province: "",
                order_id: "",
                notes: "",
                city: "",
                properties: [],
                products: [],
                postal_code: "",
            },
            // products: [],
            productsLoaded: null,
            addProduct: "",
            editMode: false,
            showModal: false,
            table_data: [],
            currentPage: 1,
            perPage: 5,
        };
    },
    methods: {
        ...productMethods,
        ...orderMethods,
        getItemTotal(price, quantity) {
            let total = Number(price) * Number(quantity);
            if (this.mustIncludeVAT) {
                total = total * 1.15;
            }
            return total.toFixed(2);
        },
        getItemTotalExcludingVat(price, quantity) {
            let total = this.getItemTotal(price, quantity);
            if (total == 0) {
                return 0;
            }
            if (this.mustIncludeVAT) {
                total = total / 1.15;
            }
            return total.toFixed(2);
        },
        getItemTotalVat(price, quantity) {
            let total = this.getItemTotal(price, quantity);
            if (total > 0) {
                total = total - this.getItemTotalExcludingVat(price, quantity);
                return total.toFixed(2);
            }
            return 0;
        },
        getGroupNames(groups) {
            return groups.map(group => group.name).join(', ');
        },
        setTableData() {
            if (this.order_data == null) {
                // read only mode, no need for cloneDeep
                this.table_data = [];
            } else {
                this.table_data = cloneDeep(this.order_data.products);
            }
        },
        handleUpdate(val) {
            //* check if updated order qty is 0   -> 0 means delete
            if (val <= 0) {
                // find product object where the quantity is 0 (get item to remove from array)
                let index = this.table_data.findIndex((product) => {
                    return product.pivot.quantity == 0;
                });
                // quantity was set to 0, passing true to restore the value if confirm is closed
                this.showRemoveItemAlert(index, true);
            }
        },
        showRemoveItemAlert(order_index, should_reset_quantity = false) {
            //get name of the item to be removed
            const title = this.table_data[order_index].name;
            this.$swal
                .fire({
                    title: `Remove ${title}`,
                    // text: "You won't be able to undo this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes, remove!",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.table_data.splice(order_index, 1);
                    } else {
                        //* reset value with the min order quantity
                        if (should_reset_quantity) {
                            this.table_data[order_index].pivot.quantity = 1;
                        }
                    }
                });
        },
        undo() {
            this.editMode = false;
        },
        editOrder() {
            // this.$nextTick(() => {
            //     this.editMode = true;
            // });
            this.editMode = true;
        },
        updateOrderDetails() {
            this.orderToUpdate = null;
            this.orderToUpdate = {
                contact_email: "",
                courier_url: "",
                contact_person: "",
                contact_no: "",
                delivery_address: "",
                province: "",
                order_id: "",
                notes: "",
                city: "",
                properties: [],
                products: [],
                postal_code: "",
            };

            this.orderToUpdate.contact_email = this.selected_order_details.contact_email;
            this.orderToUpdate.courier_url = this.selected_order_details.tracking_url;
            this.orderToUpdate.contact_person = this.selected_order_details.contact_person;
            this.orderToUpdate.contact_no = this.selected_order_details.contact_no;
            this.orderToUpdate.delivery_address = this.selected_order_details.delivery_address;
            this.orderToUpdate.province = this.selected_order_details.province;
            this.orderToUpdate.order_id = this.selected_order_details.id;
            this.orderToUpdate.notes = this.selected_order_details.notes;
            this.orderToUpdate.city = this.selected_order_details.city;
            this.orderToUpdate.postal_code = this.selected_order_details.postal_code;

            // this.orderToUpdate.properties =
            // this.selected_order_details.properties;

            this.table_data.map((element) => {
                this.orderToUpdate.products.push({
                    id: element.id,
                    qty: element.pivot.quantity,
                });
            });

            this.$swal
                .fire({
                    title: `Update the order`,
                    text: "You won't be able to undo this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes, update!",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.updateOrder(this.orderToUpdate).then(() => {
                            // this.getBranchOrders(-1);
                            this.getOrderDetails(this.order_data.id);
                        });
                    }
                });
            this.editMode = false;
        },
        addOrderProduct() {
            var el = this;
            var findIndex = _.findIndex(this.all_products, function (o) {
                return o.name == el.addProduct;
            });
            if (findIndex > -1) {
                let productData = {
                    id: this.all_products[findIndex].id,
                    name: this.all_products[findIndex].name,
                    sku: this.all_products[findIndex].sku,
                    group: this.all_products[findIndex].group,
                    qty: 1,
                    price: parseFloat(this.all_products[findIndex].price),
                };

                // this.table_data.push(productData);
                this.table_data.push({
                    group: productData.group,
                    id: productData.id,
                    name: productData.name,
                    pivot: {
                        quantity: productData.qty,
                        order_id: 0,
                        product_id: productData.id,
                    },
                    price: productData.price,
                });
                this.addProduct = "";
            }
        },
    },
    mounted() {
        this.editMode = false;

        if (this.all_products.length == 0) {
            this.getProducts().then(() => {
                this.productsLoaded = true;
            });
        } else {
            this.productsLoaded = true;
        }
        this.setTableData();
    },
};
</script>

<template>
    <div>
        <!-- viewable is run for the next button, but i used it here becuase the ! of it will be the state of the other tab -->
        <div class="d-flex justify-content-between mt-2 change-flex-breakpoint">
            <h4>Orders</h4>
            <!-- viewable is run for the next button, but i used it here becuase the ! of it will be the state of the other tab -->
            <div class="change-flex-breakpoint">
                <button v-if="editMode" type="button" class="btn btn-danger mb-2 mr-2 float-left" @click="updateOrderDetails">
                    <i class="mdi mdi-plus mr-1"></i> Update the order
                </button>
                <button v-if="editMode" type="button" class="btn btn-primary mb-2 mr-2 float-left" @click="undo">
                    <i class="mdi mdi-plus mr-1"></i> Cancel edit
                </button>
            </div>
            <!-- != 5 order is not dispatched  -->
            <button
                v-if="!editMode && order_data.status_id != 5 && read_only && (user_role == `admin` || user_role == `production`)"
                type="button"
                class="btn btn-success mb-2 mr-2 float-left"
                @click="editOrder"
            >
                <i class="mdi mdi-plus mr-1"></i> Edit Order
            </button>
        </div>
        <div class="cols-12" v-if="productsLoaded">
            <div class="card-body" v-if="editMode">
                <vue-bootstrap-autocomplete
                    v-model="addProduct"
                    noResultsInfo="No matching products found"
                    placeholder="Search for a product here"
                    @hit="addOrderProduct()"
                    :data="searchProducts"
                >
                </vue-bootstrap-autocomplete>
            </div>
        </div>

        <b-row>
            <b-col cols="12">
                <div class="table-responsive">
                    <table class="table table-centered mb-0 table-nowrap table-striped table-bordered">
                        <thead class="darker-table-head">
                            <tr>
                                <th>Product</th>
                                <th>Product Desc</th>
                                <th>Price</th>
                                <th v-show="mustIncludeVAT" style="width: 100px">Total Excl VAT</th>
                                <th v-show="mustIncludeVAT" style="width: 100px">Total VAT</th>
                                <th style="width: 100px">Total</th>
                                <th style="width: 200px" colspan="2">Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(order, index) in paginatedItems" :key="order.id">
                                <td>
                                    <img
                                        v-if="order.public_img_src"
                                        :src="order.public_img_src"
                                        alt="product-img"
                                        title="product-img"
                                        class="avatar-sm"
                                    />
                                    <img
                                        v-else
                                        src="#"
                                        alt="product-img"
                                        title="product-img"
                                        class="avatar-sm"
                                    />
                                </td>
                                <td>
                                    <h5 class="font-size-14 text-truncate">
                                        <router-link to="/ecommerce/product-detail" class="text-dark">
                                            {{ order.name }}
                                        </router-link>
                                    </h5>
                                    <p class="mb-0">
                                        Group :
                                        <span class="font-weight-medium">{{ getGroupNames(order.groups) }}</span>
                                    </p>
                                </td>
                                <td>R{{ order.price }}</td>
                                <td v-if="mustIncludeVAT" style="width: 200px">R{{ getItemTotalExcludingVat(order.price, order.pivot.quantity) }}</td>
                                <td v-if="mustIncludeVAT" style="width: 200px">R{{ getItemTotalVat(order.price, order.pivot.quantity) }}</td>
                                <td style="width: 200px">R{{ getItemTotal(order.price, order.pivot.quantity) }}</td>
                                <td style="width: 100px">
                                    <span v-show="!editMode">
                                        {{ order.pivot.quantity }}
                                    </span>
                                    <span v-show="!is_order_readonly && editMode">
                                        <b-form-input
                                            min="0"
                                            @change="handleUpdate"
                                            debounce="100"
                                            style="width: 100px"
                                            type="number"
                                            v-model="order.pivot.quantity"
                                        ></b-form-input>
                                    </span>
                                </td>
                                <td v-if="!is_order_readonly && editMode == true" style="width: 50px">
                                    <a @click="showRemoveItemAlert(index)" href="javascript:void(0);" class="action-icon text-danger">
                                        <i class="mdi mdi-trash-can font-size-18"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <b-alert class="mt-2" :show="table_data.length == 0" dismissible> No Products Added. </b-alert>

                    <div class="pagination-container">
                        <b-pagination
                            v-show="table_data.length > 0"
                            v-model="currentPage"
                            size="md"
                            :per-page="perPage"
                            :items="paginatedItems"
                            :total-rows="table_data.length"
                            first-text="First"
                            prev-text="Prev"
                            next-text="Next"
                            last-text="Last"
                            align="center"
                        ></b-pagination>
                    </div>
                    <!-- <div class="d-flex justify-content-between mt-2">
                        <h4></h4>

                        <button
                            v-if="editMode"
                            type="button"
                            class="btn btn-danger mb-2 mr-2 float-left"
                            @click="updateOrder"
                        >
                            <i class="mdi mdi-plus mr-1"></i> update Order
                        </button>
                    </div> -->
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss">
@media only screen and (max-width: 750px) {
    .change-flex-breakpoint {
        display: flex;
        flex-direction: column;

        margin-left: auto;
        margin-right: auto;
        align-items: center;
        text-align: center;
    }
}
.darker-table-head {
    background-color: #74788d;
    color: white;
}
.pagination-container {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white; /* You can adjust the background color to match your design */
    z-index: 999; /* Ensure the pagination bar stays on top of other elements */
}
</style>
